html,body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
* {
  font-family: 'Poppins', sans-serif !important;
  outline:none!important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}
.App {
  text-align: center;
  background-color: #f2f2f2;
  /* 
  background: linear-gradient(90deg, rgba(0,165,72,1) 0%, rgba(85,191,238,1) 100%, rgba(0,212,255,1) 100%); */
}
.main_content_area:before{
  content: "";
  height: 64px;
  background: linear-gradient(90deg, rgb(0, 165, 72) 0%, rgb(85, 191, 238) 100%, rgb(0, 212, 255) 100%);
  width: 100vw;
  display: flex;
  position: absolute;
}
.appBar-pages{
  position: fixed!important;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0,165,72,1) 0%, rgba(85,191,238,1) 100%, rgba(0,212,255,1) 100%);
  margin-top: -56px;
}
.big-div-content,.firm-detail-inner{
  padding-top: 56px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #fff; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin-top:0
}
.app_header_main{background-color: transparent!important;box-shadow: none!important;}

.image-container img {
  max-height: 30vh;
  width: 100vw;
  object-fit: cover;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.section_title{
  text-align: left;
  padding: 2.177rem 0 0 20px;
}
.sliderImage {
  max-width: 96vw;
  max-height: 40vh;
  height: 40vh;
  object-fit: cover;
  width: 100%;
}

.fast_buttons_home {
  flex-direction: column;
  font-size: 10px !important;
  font-weight: 500!important;
  border: none !important;
  max-width: 25%;
  width: 25%;
  justify-content: start!important;
  gap: 1rem;
  color: black!important;
  text-transform: capitalize!important;
  line-height: 0!important;
}
.main_content_outer{display:flex;justify-content: center;}
.fast_buttons_home img {
  max-height: 64px;
}

.main_content_box {
  display: flex;
  flex-wrap: wrap;
  min-width: 90vw;
  width: 100%;
  margin-top: 0;
  row-gap: .775rem;
}
.home_news_section{
  margin-top: 0;
  padding:0 1%;
  display: flex;
  flex-wrap: wrap;
  gap:1%;
  justify-content: center;
}
.scrollable-h {
  max-height: fit-content;
    overflow: scroll;
    max-width: -webkit-fill-available;
    width: 200vw;
    word-break: keep-all;
    margin-bottom: 0;
    white-space: nowrap;
    display: flex;
    gap: 1rem;
    padding: 1rem 1rem;
    position:relative;
}
.newsCard{
  min-width: -webkit-fill-available;
  border-radius: 10px!important;
  box-shadow: none!important;
  border: 1px solid #dddddd5e;
  text-decoration: none!important;
}
.newsCard.home{
  min-width:70vw!important
}
.card-content-title{
  text-align: left;
  font-weight: 400!important;
  font-size: 15px!important;
}
.card-content{
  text-align: left!important;
  font-size: 11px!important;
  color: rgb(0 0 0 / 70%)!important;
}
.slick-dots .slick-active button{
  font-family: 'Courier New', Courier, monospace;
}
.slick-dots li{
  width: 8px;
}
.slick-dots li.slick-active{
  width: 18px;
}
.slick-dots li button:before{
  background: gray;
  border-radius: 20px;
  width: 8px;
  height: 8px;
  content:'';
  transition: all .25s ease;
}
.slick-dots li.slick-active button:before{
  background: #878787;
  border-radius: 20px;
  width: 18px;
  height: 8px;
  content:'';
}
.section_fast_title{
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: 0;
  text-align: center;
  font-size: 1rem!important;
}
/* .slider-item{padding:1rem} */
.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}
.slider-item img{border-radius: .889rem;}
.mainSlider .slider-item img{border-radius: .889rem;box-shadow: 0px 1px 5px #0000007d;}
.slider-item{
  width: 100%;
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
}
.mainSlider{margin-top: .555rem;}
.appBarBackButton{margin: 0!important; padding: 0!important;}
.appBarHeaderTitlePages{
  font-size: .875rem!important;
  padding-left: 1rem;
}
.slick-dots{
  bottom:0!important;
  position: relative!important;
}
div#root{
  min-height: 100vh;
  background-color: #f7f7f7;
}
svg[data-testid="ArrowForwardIosIcon"]{
  font-size: 1rem!important;
}
.sidebar_list_image{
  max-width: 32px!important;
  max-height: 32px!important;
}
.sidebar_list_text{
  font-size: 11px!important;
}
.skipAdBtn{position:absolute!important;right:1rem;top:1rem;}
.MuiDrawer-paper{background-color: #f2f2f2!important;}
@media (max-height:580px){
  .sidebar_list_text span{
    font-size:11px!important
  }
  .sidebar_list_image {
    max-width: 24px !important;
    max-height: 24px !important;
  }
  .sidebar_list_item{padding:3px 1.175rem!important}
  .sidebar_list_item .MuiListItemAvatar-root{min-width:36px!important}
}
.sidebar_bottom_button_group{
  width: 100%;
  justify-content: space-between;
}
.sidebar_bottom_button_group button{
  flex:1;
  flex-basis:33.33%;
  width:33.33%;
  background: #f8f8f8;
  border-radius: 0!important;
}
.homeFirmCard {
  width: 70vw;
  max-width: unset;
  min-width: 70vw;
  box-shadow: none!important;
  border: none!important;
  border-radius: 10px!important;
}
.homeFirmCard .firm-bg{
  border-top-left-radius: 10px!important;
  border-top-right-radius: 10px!important;
}
.homeFirmCard .firm-logo{
  border-radius: 10px!important;
}
 
.homeFirmCard .container_content{
  padding: 16px 6px !important;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}
.homeFirmCard .secondary_div{
  display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-left: 1rem;
    max-width: calc(100% - 10px);
    overflow: hidden;
}
.sidebar_bottom_button_group button{
  color: #02a54c!important;
}
.header_buttons{
  padding:0!important;
  margin:0!important
}
@media (max-height:768px){
  .sliderImage {
    height: 40vh;
    min-height: 366px!important;
    max-height: unset!important;
    max-width: 96vw;
    object-fit: cover;
    width: 100%;
    object-fit: fill!important;
}
}