.firm-header-title{
    font-size: 14px!important;
}
.innerPage{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem 0;
}
.innerSlider{margin-top: 1rem;}
.innerPageSliderItem{width: 100vw;object-fit: cover;max-height: 24vh;max-width: 90vw;}
.firm-detail-inner{
    background-color: #f7f7f7;
}
.innerSlider ul.slick-dots li button:before{
    background: #d0d5dd!important;
    opacity: .7!important;
}
.innerSlider ul.slick-dots li.slick-active button:before {
    background: #9da1a9 !important;
    opacity: 1 !important;
}
.firmInnerButtonGroup{
    width:100%;
    display: flex;
    gap:5px
}
.firmInnerButton{
    flex-direction: column!important;
    background-color: white!important;
    color: black!important;
    font-size: 10px!important;
    width: 33%!important;
    padding: 1rem 0!important;
    border-radius: 10px!important;
    gap:.5rem;
}
.firmInnerButton.half{
    width: 50%!important;
}
.firmInnerButton span{margin:0!important}
.firmInnerButton.w-100{width:100%!important}
.firmInnerCards{box-shadow: none!important;border-radius: 10px!important;}
/* .ymaps-2-1-79-map:nth-child(2){
    display:none!important
} */
.firmVipTopCard{
    position: relative;
    box-shadow: none!important;
    margin-bottom: 1rem;
}
.firmInnerCardTop{display:flex;gap:1rem}
.firmInnerCardTop img{max-width: 64px; max-height: 64px;}
.firm_site_btn svg{font-size:13px!important}