.innerPageListItem{
    margin-bottom: 0.575rem;
    box-shadow: none;
    max-width: 96%;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    background-color: #fff!important;
}
.innerPageListOuter{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7!important;
}
.innerPageListItem.vip{
    background-color: #0097a7!important;
}
.innerPageListItem.vip.cekgonder{
    background: #00a4dd!important;
}
.innerPageListItem.vip span,.innerPageListItem.vip p,.innerPageListItem.vip svg{
    color:white!important
}
.innerPageListItem.vip span{
    font-weight: 500!important;
}
.innerPageListItem.vip p{
    max-width: 90%;
    font-size: 10px;
}
.innerPage-header-bottom{
    display: flex;
    width: 96%;
    justify-content: space-between;
    padding: 1rem .5rem;
    align-items: center;
    gap: .775rem;
    background-color: #f7f7f7!important;
}
.innerPage-header-bottom input{
    border: 1px solid #f1f1f1;
    border-radius: 0.475rem;
    padding: 0.575rem 0.475rem 0.575rem 2.175rem;
    width: -webkit-fill-available;
    background-color: #f5f5f5;
    background: #fff url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E") no-repeat;
    background-position: .475rem;
}

.loadingSkeletonFirmList{
    display: flex;
    gap: 1rem;
    justify-content: left;
    padding-left: .5rem;
    margin-bottom: 1rem;
}
.innerPageListItemInner{
    color: black!important;
    box-shadow: none !important;
    padding: 0.675rem 0.575rem!important;
    border-radius: inherit;
}
.MuiListItem-root .MuiTouchRipple-child {
    background-color: red;
}
.taksiInnerBtn p{
    font-size:10px!important
}
.taksiInnerBtn img{object-fit: contain!important;}
.taksiInnerBtn span{font-weight: 500;}